import { FunctionComponent, useEffect } from 'react';
import { navigate } from 'gatsby';

const IndexPage: FunctionComponent = () => {
    useEffect(() => {
        navigate('/catalog');
    }, []);
    return null;
};

export default IndexPage;
